<template>
  <div class="subscription-details">
    <div>
      2Checkout Subscription Reference: {{ subscriptionData.SubscriptionReference }}
    </div>
    <div>
      <v-btn>
        Reactivate
      </v-btn>
    </div>
    <div>
      Customer: <router-link :to="`/dashboard/user/${$route.params.userId}`">{{ subscriptionData.EndUser.FirstName }}&nbsp;{{ subscriptionData.EndUser.LastName }} ({{ $route.params.userId }})</router-link>
    </div>
    <div>
      Product: {{ subscriptionData.Product.ProductName }}
    </div>
  </div>
</template>
<script>

export default {
  props: {
    subscriptionData: {
      type: Object,
      required: true
    }
  },
  name: 'SubscriptionDetails'
}
</script>