<template>
  <div class="subscription-info">asd</div>
</template>
<script>

export default {
  props: {
    subscriptionData: {
      type: Object,
      required: true
    }
  },
  name: 'SubscriptionInfo'
}
</script>