<template>
  <v-toolbar
    outlined
    rounded
    flat
    class="status-toolbar"
    :style="{ 
      borderColor: itemData.color 
    }"
  >
    <v-toolbar-title
      class="body-1 font-weight-medium"
      :style="{
        color: itemData.color
      }"
    >
      Status: {{ itemData.text }}
    </v-toolbar-title>
  </v-toolbar>
</template>
<script>

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    item: {
      required: true
    }
  },
  name: 'StatusToolbar',
  data: () => ({}),
  computed: {
    itemData() {
      const result = this.items.find(({ value }) => this.item === value ) || {
        value: null,
        color: null
      }
      return result
    }
  }
}
</script>
<style lang="scss">
.status-toolbar {
  position: relative;
}
</style>
