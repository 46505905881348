<template>
  <div class="subscriptions-history">
    <v-data-table 
      :items="items"
      :headers="headers"
      :loading="inProcess"
      dense
      hide-default-footer
    >
      <template v-slot:item.order="{ item }">
        <router-link :to="`/dashboard/user/${userId}/customer/${customerId}/subscription/${item.data.SubscriptionReference}/order/${item.data.LatestSubscriptionOrder.RefNo}`">{{ item.order }}</router-link>
      </template>
      <template v-slot:item.subscriptionId="{ item }">
        <router-link :to="`/dashboard/user/${userId}/customer/${customerId}/subscription/${item.data.SubscriptionReference}`">{{ item.subscriptionId }}</router-link>
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  props: {
    subscriptionsData: {
      required: true
    },
    customersData: {
    },
    userData: {
    }
  },
  name: 'SubscriptionsHistory',
  data: () => ({
    headers: [
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Change',
        value: 'change'
      },
      {
        text: 'SubscriptionId',
        value: 'subscriptionId'
      },
      {
        text: 'Order',
        value: 'order'
      }
    ],
    responses: [],
    inProcess: true,
    history: null
  }),
  async created() {
    this.init(this.subscriptionsData)
  },
  computed: {
    userId() {
      const { userId } = this.$route.params
      return userId
    },
    customerId() {
      const { customerId } = this.$route.params
      return customerId
    },
    items() {
      const allHistory = this.responses.reduce((acc, res) => {
        acc = [...acc, ...res]
        return acc
      }, [])
      const result = allHistory.map(historyItem => {
        const { StartDate, ReferenceNo: order, SubscriptionReference:subscriptionId } = historyItem
        return {
          date: StartDate,
          change: this.changeByType(historyItem),
          data: this.subscriptionDataByReference(historyItem.SubscriptionReference),
          subscriptionId,
          order
        }
      })

      return result
    }
  },
  methods: {
    subscriptionDataByReference(reference) {
      return this.subscriptionsData.find(sub => sub.SubscriptionReference === reference) || null
    },
    changeByType(item) {
      const { SubscriptionReference } = item
      let result = `The subscription ${SubscriptionReference} ...`
      console.log(item)
      return result
    },
    async init() {
      this.inProcess = true

      const { externalData = {}} = this.userData[1]
      const { subscriptionsHistory = {}, subscriptions = {} } = externalData && externalData.verifone || {}
      console.log('history',  subscriptionsHistory, this.userData[1])

      // this.responses = await Promise.all(to.map(sub => {
      //   const { SubscriptionReference } = sub
      //   return  this.$store.dispatch('getSubscriptionHistory', SubscriptionReference)
      // }))

      this.responses = Object.values(subscriptions).filter(subscription => subscription.CustomerReference == this.customerId).map(subscription => Object.values(subscriptionsHistory).filter(historyItem => historyItem.SubscriptionReference == subscription.SubscriptionReference))

      this.inProcess = false
    }
  }
}
</script>