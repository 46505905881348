<template>
  <div class="subscription-end-user-details">
    <v-list
      dense
    >
      <v-list-item
        v-for="(val, key) in subscriptionData.EndUser"
        :key="`end-user-field-${key}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ key }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>
            {{ val }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>

export default {
  props: {
    subscriptionData: {
      type: Object,
      required: true
    }
  },
  name: 'SubscriptionEndUserDetails'
}
</script>