<template>
  <loader-hoc v-model="inProcess" style="height: 100%;">
    <status-toolbar
      class="mb-4"
      :items="[{ value: true, text: 'ACTIVE', color: 'green' }, { value: false, text: 'INACTIVE', color: 'red' }]"
      :item="subscription.SubscriptionEnabled"
      v-if="subscription"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <card
            title="Subscription Details"
          >
            <subscription-details 
              :subscription-data="subscription"
            />
          </card>
        </v-col>
        <v-col cols="6">
          <card 
            title="Subscription Statistics"
          >
            <subscription-statistics 
              :subscription-data="subscription"
            />
          </card>
        </v-col>
        <v-col cols="6">
          <card
            title="Subscription Info"
          >
            <subscription-info 
              :subscription-data="subscription"
            />
          </card>
        </v-col>
        <v-col cols="6">
          <card
            title="End User Details"
          >
            <subscription-end-user-details
              :subscription-data="subscription"
            />
          </card>
        </v-col>
        <v-col cols="12">
          <card
            title="Subscription History"
          >
            <subscription-history
              :user-data="userData"
              :subscriptions-data="[subscription]"
            />
          </card>
        </v-col>
      </v-row>
    </v-container>
  </loader-hoc>
</template>
<script>

import LoaderHoc from '@/components/loader-hoc'
import Card from '@/components/card'
import StatusToolbar from '@/components/status-toolbar'
import SubscriptionDetails from '@/components/subscription/details'
import SubscriptionInfo from '@/components/subscription/info'
import SubscriptionEndUserDetails from '@/components/subscription/endUserDetails'
import SubscriptionStatistics from '@/components/subscription/statistics'
import SubscriptionHistory from '@/components/customer/history'

export default {
  name: 'DashboardCustomerSubscription',
  components: {
    LoaderHoc,
    Card,
    StatusToolbar,
    SubscriptionDetails,
    SubscriptionInfo,
    SubscriptionEndUserDetails,
    SubscriptionStatistics,
    SubscriptionHistory
  },
  data: () => ({
    inProcess: true,
    userData: null,
    subscription: null
  }),
  created() {
    this.init()
  },
  computed: {
    userId() {
      const { userId } = this.$route.params
      return userId
    },
    subscriptionId() {
      const { subscriptionId } = this.$route.params
      return subscriptionId
    }
  },
  methods: {
    async init() {
      this.inProcess = true

      this.userData = await this.$store.dispatch('getCustomer', this.userId)
      
      this.subscription = this.userData[1].externalData.verifone.subscriptions[this.subscriptionId]

      this.inProcess = false
    }
  }
}
</script>