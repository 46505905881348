<template>
  <div class="subscription-statistics">
    {{ subscriptionData }}
  </div>
</template>
<script>

export default {
  props: {
    subscriptionData: {
      type: Object,
      required: true
    }
  },
  name: 'SubscriptionStatistics'
}
</script>